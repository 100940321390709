import React, { useState } from "react";
import { useParams } from "react-router";
import { Button } from "react-bootstrap";
import { CartState } from "../../context/Context";
import taza from "../../images/raquetas/taza.png";
import bolso from "../../images/raquetas/bolso.png";
import billetera from "../../images/raquetas/billetera.png";
import remera from "../../images/raquetas/remera.jpeg";
import cuellos from "../../images/raquetas/cuellos.jpeg";
import toalla from "../../images/raquetas/toalla.jpeg";
import gorra from "../../images/raquetas/gorra.jpeg";
import llavero from "../../images/raquetas/llavero.png"; 
import kit from "../../images/raquetas/kit.jpeg";
import souvenirCumple from "../../images/raquetas/souvenirCumple.jpeg";
 
export default function ItemDetailContainer(/* {prod} */) {
  const [items, setItems] = useState();
  const { id } = useParams();
  const {
    state: { cart },
    dispatch,
  } = CartState();
  let productos = [
    {
      name: "Taza Personalizada",
      descripcion:
        "Comienza tu día con una sonrisa y una taza personalizada. Nuestras tazas sublimadas son ideales para añadir un toque personal a tu café o té matutino. Elige tus imágenes, frases o colores favoritos y disfruta de una taza única que hará que cada sorbo sea especial.",
      stock: 75,
      price: "A acordar",
      id: 1,
      image: taza, // Array de imágenes
      peso: "300 g",
      aro: "100 in²",
      patronEncordado: "16/19",
      grip: "4 3/4 4 1/4",
      balance: "320 mm",
      largo: "685 mm",
    },
    {
      name: "Bolsa Personalizada X 100",
      descripcion:
        "¡Lleva tu estilo a todas partes con nuestras bolsas personalizadas! Perfectas para cualquier ocasión, nuestras bolsas sublimadas te permiten mostrar tu personalidad única. Añade tus diseños, colores y mensajes favoritos para una bolsa que es tan práctica como fabulosa.",
      stock: 30,
      price: "A acordar",
      id: 2,
      image: bolso, // Array de imágenes
      peso: "300 g",
      aro: "100 in²",
      patronEncordado: "16/19",
      grip: "4 3/4 4 1/4",
      balance: "320 mm",
      largo: "685 mm",
    },
    {
      name: "LLavero Personalizado X 100",
      descripcion:
        "¡Lleva tus recuerdos y estilo contigo a donde vayas con nuestros llaveros personalizados! Compactos y prácticos, estos llaveros son perfectos para añadir un toque personal a tus llaves. Personaliza con tus fotos, colores o mensajes favoritos para un accesorio pequeño pero significativo.",
      stock: 40,
      price: "A acordar",
      id: 3,
      image: llavero, // Array de imágenes
      peso: "295 g",
      aro: "100 in²",
      patronEncordado: "16/19",
      grip: "4 3/4 4 1/4",
      balance: "320 mm",
      largo: "685 mm",
    },
    {
      name: "Billetera Personalizada",
      descripcion:
        "Mantén tus pertenencias seguras con estilo gracias a nuestras billeteras personalizadas. Perfectas para un regalo o para ti mismo, estas billeteras permiten incorporar tus colores, diseños y mensajes preferidos. Prácticas y elegantes, ¡son el accesorio perfecto para cualquier ocasión!",
      stock: 40,
      price: "A acordar",
      id: 4,
      image: billetera, // Array de imágenes
      peso: "310 g",
      aro: " 100 in²",
      patronEncordado: "18/20",
      grip: "4 1/4",
      balance: "315mm",
      largo: "685mm",
    },
    {
      name: "Remera Personalizada",
      descripcion:
        "Expresa tu originalidad con nuestras remeras sublimadas al gusto. Hechas con materiales de alta calidad, nuestras remeras no solo son cómodas, sino que también te permiten llevar tus diseños y colores favoritos a donde vayas. ¡Sé el centro de atención con una remera única y personalizada!",
      stock: 40,
      price: "A acordar",
      id: 5,
      image: /* [ */remera/* , remera2, remera3, remera4] */, // Array de imágenes
      peso: "320 g",
      aro: " 98 in²",
      patronEncordado: "18/20",
      grip: "4 3/4",
      balance: "310mm",
      largo: "685mm",
    },{
      name: "Cuellitos Personalizados",
      descripcion:
        "Destaca con nuestros cuellos sublimados: diseños vibrantes, duraderos y cómodos. El complemento perfecto para cualquier ocasión. ¡Exprésate con estilo!",
      stock: 40,
      price: "A acordar",
      id: 6,
      image:  /* [ */cuellos/* , cuellos2, cuellos3, cuellos4] */,
      peso: "320 g",
      aro: " 98 in²",
      patronEncordado: "18/20",
      grip: "4 3/4",
      balance: "310mm",
      largo: "685mm",
    } , 
    {
      name: "Toallas Personalizadas",
      descripcion:
        "Nuestras toallas sublimadas combinan suavidad y durabilidad con diseños vibrantes que no se desvanecen. Perfectas para la playa, el gimnasio o el hogar. ¡Añade color y estilo a tu rutina diaria!",
      stock: 40,
      price: "A acordar",
      id: 7,
      image: toalla,
      peso: "320 g",
      aro: " 98 in²",
      patronEncordado: "18/20",
      grip: "4 3/4",
      balance: "310mm",
      largo: "685mm",
    } ,
    {
      name: "Gorras Personalizadas",
      descripcion:
        "Nuestras gorras sublimadas ofrecen estilo y protección con diseños únicos y duraderos. Ideales para destacar en cualquier ocasión. ¡Lleva tu personalidad a todas partes!",
      stock: 40,
      price: "A acordar",
      id: 8,
      image: gorra,
      peso: "320 g",
      aro: " 98 in²",
      patronEncordado: "18/20",
      grip: "4 3/4",
      balance: "310mm",
      largo: "685mm",
    } ,
    {
      name: "Latas Souvenir Personalizadas",
      descripcion:
        "Nuestras latas personalizadas ofrecen estilo y protección con diseños únicos y duraderos. Ideales para destacar en cualquier ocasión. ¡Lleva tu personalidad a todas partes!",
      stock: 40,
      price: "A acordar",
      id: 9,
      image: souvenirCumple,
      peso: "320 g",
      aro: " 98 in²",
      patronEncordado: "18/20",
      grip: "4 3/4",
      balance: "310mm",
      largo: "685mm",
    } ,
    {
      name: "Kit Limpieza Personalizado",
      descripcion:
        "Nuestros Kit Limpieza personalizadas ofrecen estilo y protección con diseños únicos y duraderos. Ideales para destacar en cualquier ocasión. ¡Lleva tu personalidad a todas partes!",
      stock: 40,
      price: "A acordar",
      id: 10,
      image: kit,
      peso: "320 g",
      aro: " 98 in²",
      patronEncordado: "18/20",
      grip: "4 3/4",
      balance: "310mm",
      largo: "685mm",
    } 
  ];

  const getItem = new Promise((resolve, reject) => {
    setTimeout(() => resolve(productos), 10000);
  });
  getItem.then((resolve) => setItems(resolve[id - 1]));
  console.log(productos)
  return (
    <>
      {productos[id - 1] ? (
        <>
          <div class="wrapperCard col-9">
            <div class="product-img">
              <img src={productos[id - 1].image} alt={productos[id - 1].name} />
            </div>
            <div class="product-info">
              <div class="product-text">
                <h1 class="product-title">{productos[id - 1].name}</h1>

                <p>{productos[id - 1].descripcion}</p>
              </div>
              <div class="product-price-btn">
                <p>
                  <span>{productos[id - 1].price}</span>
                </p>
                {cart.some((p) => p.id === productos[id - 1].id) ? (
                  <Button
                    className="noBuyButton"
                    variant="danger"
                    onClick={() =>
                      dispatch({
                        type: "REMOVE_FROM_CART",
                        payload: productos[id - 1],
                      })
                    }
                  >
                    Eliminar
                  </Button>
                ) : (
                  <Button
                    className="buyButton"
                    onClick={() =>
                      dispatch({
                        type: "ADD_TO_CART",
                        payload: productos[id - 1],
                      })
                    }
                    disabled={!productos[id - 1].stock}
                  >
                    {!productos[id - 1].stock ? "Sin Stock" : "Agregar "}
                  </Button>
                )}
              </div>
            </div>
          </div>
          
        </>
      ) : (
        <h2>Cargando....</h2>
      )}
    </>
  );
}
