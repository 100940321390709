import { useEffect, useState } from "react";
import { Button, Col, Form, Image, ListGroup, Row } from "react-bootstrap";
import { AiFillDelete } from "react-icons/ai";
import { CartState } from "../context/Context";

const Cart = () => {
  const {
    state: { cart },
    dispatch,
  } = CartState();
  const [total, setTotal] = useState();

  useEffect(() => {
    setTotal(
      cart.reduce((acc, curr) => acc + Number(curr.price) * curr.qty, 0)
    );
  }, [cart]);

  // Función para generar el mensaje de WhatsApp
  const generateWhatsAppMessage = () => {
    const message = `Hola! Estoy interesado en comprar los siguientes productos:
    `;
    const productList = cart.map((prod) => {
      return `${prod.name} - Cantidad: ${prod.qty}`;
    });
    const productMessage = productList.join("\n");

    const totalPriceMessage = `Total: $${total}`;

    const encodedMessage = encodeURIComponent(
      `${message}${productMessage}\n${totalPriceMessage}`
    );
    return encodedMessage;
  };
  console.log(generateWhatsAppMessage());

  return (
    <>
      <div className="indicadores"></div>
      <h2 className="cartH">Su carrito de compra está listo!</h2>
      <div className="home">
        <div className="productContainer">
          <ListGroup>
            {cart.map((prod) => (
              <ListGroup.Item key={prod.id}>
                <Row>
                  <Col md={2}>
                    <Image
                      src={prod.image}
                      alt={prod.name}
                      width={40}
                      height={40}
                      fluid
                      rounded
                    />
                  </Col>
                  <Col md={2}>
                    <span>{prod.name}</span>
                  </Col>
                  <Col md={2}>${prod.price}</Col>
                  <Col md={2}>
                    <Form.Control
                      as="select"
                      value={prod.qty}
                      onChange={(e) =>
                        dispatch({
                          type: "CHANGE_CART_QTY",
                          payload: {
                            id: prod.id,
                            qty: e.target.value,
                          },
                        })
                      }
                    >
                      {[...Array(prod.inStock).keys()].map((x) => (
                        <option key={x + 1}>{x + 1}</option>
                      ))}
                    </Form.Control>
                  </Col>
                  <Col md={2}>
                    <Button
                      type="button"
                      variant="light"
                      onClick={() =>
                        dispatch({
                          type: "REMOVE_FROM_CART",
                          payload: prod,
                        })
                      }
                    >
                      <AiFillDelete fontSize="20px" />
                    </Button>
                  </Col>
                </Row>
              </ListGroup.Item>
            ))}
          </ListGroup>
        </div>
        <div className="filters summary">
          <span className="title">Subtotal ({cart.length}) items</span>
          <span style={{ fontWeight: 700, fontSize: 20 }}>
            Total: $ {total}
          </span>
          <Button
            type="button"
            variant="danger"
            className="compraBtn"
            disabled={cart.length === 0}
          >
            <a
              href={`https://wa.me/5491154948006?text=${generateWhatsAppMessage()}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              Comprar
            </a>
          </Button>
        </div>
      </div>
    </>
  );
};

export default Cart;

