
const Contacto = () => {
  return (
    <div className="contactoContainer col-12" id="contacto">
      <div className="col-9 " style={{ zIndex: 2, paddingTop: "40px" }}>
        <h2>Contacto</h2>

        <div class="container">
          <form id="contact" action="https://formsubmit.co/57a12b49d9713081d4ea2d232a6f7dba" method="post">
            <h3> Formulario</h3>
            <h4>Envianos tu mensaje y responderemos lo antes posible!</h4>
            <fieldset>
              <input
                placeholder="Nombre"
                type="text"
                tabindex="1"
                required
                autofocus
              />
            </fieldset>
            <fieldset>
              <input
                placeholder="Email "
                type="email"
                tabindex="2"
                required
              />
            </fieldset>
            
            
            <fieldset>
              <textarea
                placeholder="Mensaje...."
                tabindex="5"
                required
              ></textarea>
            </fieldset>
            <fieldset>
              <button
                name="submit"
                type="submit"
                id="contact-submit"
                data-submit="...Sending"
              >
                Enviar
              </button>
            </fieldset>
          </form>
        </div>
        <h5>
          Created and designed by{" "}
          <a
            href="https://portfolio-agustinllados.000webhostapp.com/"
            target="_blank"
            rel="noreferrer"
          >
            {" "}
            Agustin LLados
          </a>
          <a
            href="https://www.linkedin.com/in/agustin-llados-a39067200/"
            target="_blank"
            rel="noreferrer"
          >
            {" "}
            &nbsp; &nbsp; &nbsp; &nbsp;Linkedin
          </a>{" "}
        </h5>
      </div>
    </div>
  );
};
export default Contacto;
