import { useEffect, createContext, useContext, useReducer } from "react";
import faker from "faker";
import taza from "../images/raquetas/taza.png";
import bolso from "../images/raquetas/bolso.png";
import llavero from "../images/raquetas/llavero.png";
import billetera from "../images/raquetas/billetera.png";
import remera from "../images/raquetas/remera.jpeg";
import cuellos from "../images/raquetas/cuellos.jpeg";
import toalla from "../images/raquetas/toalla.jpeg";
import gorra from "../images/raquetas/gorra.jpeg";
import kit from "../images/raquetas/kit.jpeg";
import souvenirCumple from "../images/raquetas/souvenirCumple.jpeg";

const Cart = createContext();

const cartReducer = (state, action) => {
  switch (action.type) {
    case "ADD_TO_CART":
      return { ...state, cart: [...state.cart, { ...action.payload, qty: 1 }] };
    case "REMOVE_FROM_CART":
      return {
        ...state,
        cart: state.cart.filter((c) => c.id !== action.payload.id),
      };
    case "CHANGE_CART_QTY":
      return {
        ...state,
        cart: state.cart.filter((c) =>
          c.id === action.payload.id ? (c.qty = action.payload.qty) : c.qty
        ),
      };
    default:
      return state;
  }
};

const productReducer = (state, action) => {
  switch (action.type) {
    case "SORT_BY_PRICE":
      return { ...state, sort: action.payload };
    case "FILTER_BY_STOCK":
      return { ...state, byStock: !state.byStock };
    case "FILTER_BY_DELIVERY":
      return { ...state, byFastDelivery: !state.byFastDelivery };
    case "FILTER_BY_RATING":
      return { ...state, byRating: action.payload };
    case "FILTER_BY_SEARCH":
      return { ...state, searchQuery: action.payload };
    case "CLEAR_FILTERS":
      return { byStock: false, byFastDelivery: false, byRating: 0 };
    default:
      return state;
  }
};

const saveStateToLocalStorage = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem("state", serializedState);
  } catch (error) {
    console.error("Error saving state to localStorage:", error);
  }
};

const loadStateFromLocalStorage = () => {
  try {
    const serializedState = localStorage.getItem("state");
    return serializedState ? JSON.parse(serializedState) : undefined;
  } catch (error) {
    console.error("Error loading state from localStorage:", error);
    return undefined;
  }
};

const Context = ({ children }) => {
  const arrayP = [
    {
      name: "Taza Personalizada",
      descripcion:
        "Comienza tu día con una sonrisa y una taza personalizada. Nuestras tazas sublimadas son ideales para añadir un toque personal a tu café o té matutino. Elige tus imágenes, frases o colores favoritos y disfruta de una taza única que hará que cada sorbo sea especial.",
      stock: 75,
      price: "A acordar",
      id: 1,
      images: taza, // Array de imágenes
      peso: "300 g",
      aro: "100 in²",
      patronEncordado: "16/19",
      grip: "4 3/4 4 1/4",
      balance: "320 mm",
      largo: "685 mm",
    },
    {
      name: "Bolsa Personalizada X 100",
      descripcion:
        "¡Lleva tu estilo a todas partes con nuestras bolsas personalizadas! Perfectas para cualquier ocasión, nuestras bolsas sublimadas te permiten mostrar tu personalidad única. Añade tus diseños, colores y mensajes favoritos para una bolsa que es tan práctica como fabulosa.",
      stock: 30,
      price: "A acordar",
      id: 2,
      images: bolso, // Array de imágenes
      peso: "300 g",
      aro: "100 in²",
      patronEncordado: "16/19",
      grip: "4 3/4 4 1/4",
      balance: "320 mm",
      largo: "685 mm",
    },
    {
      name: "LLavero Personalizado X 100",
      descripcion:
        "¡Lleva tus recuerdos y estilo contigo a donde vayas con nuestros llaveros personalizados! Compactos y prácticos, estos llaveros son perfectos para añadir un toque personal a tus llaves. Personaliza con tus fotos, colores o mensajes favoritos para un accesorio pequeño pero significativo.",
      stock: 40,
      price: "A acordar",
      id: 3,
      images: llavero, // Array de imágenes
      peso: "295 g",
      aro: "100 in²",
      patronEncordado: "16/19",
      grip: "4 3/4 4 1/4",
      balance: "320 mm",
      largo: "685 mm",
    },
    {
      name: "Billetera Personalizada",
      descripcion:
        "Mantén tus pertenencias seguras con estilo gracias a nuestras billeteras personalizadas. Perfectas para un regalo o para ti mismo, estas billeteras permiten incorporar tus colores, diseños y mensajes preferidos. Prácticas y elegantes, ¡son el accesorio perfecto para cualquier ocasión!",
      stock: 40,
      price: "A acordar",
      id: 4,
      images: billetera, // Array de imágenes
      peso: "310 g",
      aro: " 100 in²",
      patronEncordado: "18/20",
      grip: "4 1/4",
      balance: "315mm",
      largo: "685mm",
    },
    {
      name: "Remera Personalizada",
      descripcion:
        "Expresa tu originalidad con nuestras remeras sublimadas al gusto. Hechas con materiales de alta calidad, nuestras remeras no solo son cómodas, sino que también te permiten llevar tus diseños y colores favoritos a donde vayas. ¡Sé el centro de atención con una remera única y personalizada!",
      stock: 40,
      price: "A acordar",
      id: 5,
      images: remera, // Array de imágenes
      peso: "320 g",
      aro: " 98 in²",
      patronEncordado: "18/20",
      grip: "4 3/4",
      balance: "310mm",
      largo: "685mm",
    },{
      name: "Cuellitos Personalizados",
      descripcion:
        "Destaca con nuestros cuellos sublimados: diseños vibrantes, duraderos y cómodos. El complemento perfecto para cualquier ocasión. ¡Exprésate con estilo!",
      stock: 40,
      price: "A acordar",
      id: 6,
      images:  cuellos,
      peso: "320 g",
      aro: " 98 in²",
      patronEncordado: "18/20",
      grip: "4 3/4",
      balance: "310mm",
      largo: "685mm",
    } , 
    {
      name: "Toallas Personalizadas",
      descripcion:
        "Nuestras toallas sublimadas combinan suavidad y durabilidad con diseños vibrantes que no se desvanecen. Perfectas para la playa, el gimnasio o el hogar. ¡Añade color y estilo a tu rutina diaria!",
      stock: 40,
      price: "A acordar",
      id: 7,
      images: toalla,
      peso: "320 g",
      aro: " 98 in²",
      patronEncordado: "18/20",
      grip: "4 3/4",
      balance: "310mm",
      largo: "685mm",
    } ,
    {
      name: "Gorras Personalizadas",
      descripcion:
        "Nuestras gorras sublimadas ofrecen estilo y protección con diseños únicos y duraderos. Ideales para destacar en cualquier ocasión. ¡Lleva tu personalidad a todas partes!",
      stock: 40,
      price: "A acordar",
      id: 8,
      images: gorra,
      peso: "320 g",
      aro: " 98 in²",
      patronEncordado: "18/20",
      grip: "4 3/4",
      balance: "310mm",
      largo: "685mm",
    } ,
    {
      name: "Latas Souvenir Personalizadas",
      descripcion:
        "Nuestras latas personalizadas ofrecen estilo y protección con diseños únicos y duraderos. Ideales para destacar en cualquier ocasión. ¡Lleva tu personalidad a todas partes!",
      stock: 40,
      price: "A acordar",
      id: 9,
      images: souvenirCumple,
      peso: "320 g",
      aro: " 98 in²",
      patronEncordado: "18/20",
      grip: "4 3/4",
      balance: "310mm",
      largo: "685mm",
    } ,
    {
      name: "Kit Limpieza Personalizado",
      descripcion:
        "Nuestros Kit Limpieza personalizadas ofrecen estilo y protección con diseños únicos y duraderos. Ideales para destacar en cualquier ocasión. ¡Lleva tu personalidad a todas partes!",
      stock: 40,
      price: "A acordar",
      id: 10,
      images: kit,
      peso: "320 g",
      aro: " 98 in²",
      patronEncordado: "18/20",
      grip: "4 3/4",
      balance: "310mm",
      largo: "685mm",
    } 
  ];

  const products = arrayP.map((item) => ({
    id: item.id,
    name: item.name,
    price: item.price,
    images: item.images, // Mantén esto como un array
    inStock: item.stock,
    fastDelivery: faker.datatype.boolean(),
    ratings: faker.random.arrayElement([1, 2, 3, 4, 5]),
}));


  const initialState = loadStateFromLocalStorage() || {
    products: products,
    cart: [],
  };

  const [state, dispatch] = useReducer(cartReducer, initialState);

  const [productState, productDispatch] = useReducer(productReducer, {
    byStock: false,
    byFastDelivery: false,
    byRating: 0,
    searchQuery: "",
  });

  useEffect(() => {
    saveStateToLocalStorage(state);
  }, [state]);

  return (
    <Cart.Provider value={{ state, dispatch, productState, productDispatch }}>
      {children}
    </Cart.Provider>
  );
};

export const CartState = () => {
  return useContext(Cart);
};

export default Context;