
import Contacto from "./Contacto";

const Footer = () => {
  return (
    <>
      <div className="footer col-12" id="Contacto">
        {/* 
      <Goal /> */}
        <Contacto />
      </div>
    </>
  );
};
export default Footer;
