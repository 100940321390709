import { Card, Button } from "react-bootstrap";
import { CartState } from "../context/Context"; 
import { Link } from "react-router-dom";

const SingleProduct = ({ prod }) => {
  const {
    state: { cart },
    dispatch,
  } = CartState();

  // Asegúrate de que prod.images sea un array y selecciona la primera imagen
  const productImage = Array.isArray(prod.images) ? prod.images[0] : prod.images;

  console.log(productImage)
  return (
    <div className="products">
      <Card>
        <Link to={`/productos/${prod.id}`}>
          
          <Card.Img
            variant="top"
            className="raqueta"
            src={productImage} // Usa la imagen correcta
            alt={prod.name}
          />
          <Card.Body>
            <Card.Title>{prod.name}</Card.Title>
            <Card.Subtitle>
              <span>{prod.price.split(".")[0]}</span>
              <br></br>
            </Card.Subtitle>
          </Card.Body>
        </Link>
        {cart.some((p) => p.id === prod.id) ? (
          <Button
            className="noBuyButton"
            variant="danger"
            onClick={() =>
              dispatch({
                type: "REMOVE_FROM_CART",
                payload: prod,
              })
            }
          >
            Eliminar del carrito
          </Button>
        ) : (
          <Button
            className="buyButton"
            onClick={() =>
              dispatch({
                type: "ADD_TO_CART",
                payload: prod,
              })
            }
            disabled={!prod.inStock}
          >
            {!prod.inStock ? "Sin Stock" : "Agregar al carrito"}
          </Button>
        )}
      </Card>
    </div>
  );
};

export default SingleProduct;
