import React from "react";

const Card = ({ caracter, title, marcaImg, marcaImgAlt ,caracterAlt}) => {
  return (
    <>
      {{ caracter, title, marcaImg, marcaImgAlt ,caracterAlt} ? (
        <article className="brand-card appear">
          <div class="cardBrand">
            <div class="wrapper">
              <img
                alt={marcaImgAlt}
                src={marcaImg}
                class="cover-image"
              />
            </div>
            <p class="title">{title}</p>
            <img
              alt={caracterAlt}
              src={caracter}
              class="character"
            />
          </div>
        </article>
      ) : (
        <h2>Cargando Cards...</h2>
      )}
    </>
  );
};

export default Card;
