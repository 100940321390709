import "./App.css";
import Header from "./components/header/Header";
import { BrowserRouter, Route } from "react-router-dom";
import Home from "./components/Home";
import Cart from "./components/Cart";
import ControlledCarousel from "./components/ControlledCarousel/ControlledCarousel";
import QuienesSomos from "./components/QuienesSomos/QuienesSomos";
import Footer from "./components/footer/Footer";
import ItemDetailContainer from "./components/ItemDetailContainer/ItemDetailContainer";
import FixUpScroll from "./FixScroll";

function App() {
  return (
    <BrowserRouter>
      <FixUpScroll>
        <Header />

        <ControlledCarousel />
        <div className="App">
          <Route path="/" exact>
            <Home />
            <a href="https://wa.me/5491154948006" className="wpp">
              <img
                width="60"
                height="60"
                src="https://img.icons8.com/color/96/whatsapp--v1.png"
                alt="whatsapp--v1"
              />
            </a><a href="https://wa.me/5491153256689" className="wpp2">
              <img
                width="60"
                height="60"
                src="https://img.icons8.com/color/96/whatsapp--v1.png"
                alt="whatsapp--v1"
              />
            </a>
            <a
              href="https://www.facebook.com/media/set/?set=a.133137371224663&type=3&mibextid=WC7FNe&rdid=alLoJaw98YTbuiJX&share_url=https%3A%2F%2Fwww.facebook.com%2Fshare%2FDEttmZDLRzvMrN9W%2F%3Fmibextid%3DWC7FNe"
              className="fk"
            >
              <img
                width="60"
                height="60"
                src="https://img.icons8.com/color/60/facebook-new.png"
                alt="facebook-new"
              />
            </a>
            <a
              href="https://www.instagram.com/sublibon.com.ar/"
              className="ig"
            >
              <img
                width="60"
                height="60"
                src="https://img.icons8.com/fluency/48/instagram-new.png"
                alt="facebook-new"
              />
            </a>
            <QuienesSomos />
            <Footer />
          </Route>
          <Route path="/cart">
            <Cart />
            <div className="indicadores"></div>
            <Footer />
          </Route>

          <Route path="/productos/:id">
            <div className="indicadores"></div>
            <ItemDetailContainer />
            <div className="indicadores"></div>
            <Footer />
          </Route>
        </div>
      </FixUpScroll>
    </BrowserRouter>
  );
}

export default App;
