import React from "react";
import Marcas from "./Marcas";
import perfil from "../../images/perfil-removebg-preview-transformed.png"

const QuienesSomos = () => {
  return (
    <>
      <div className="indicadores" id="quienesSomos"></div>
      <section className="quienesSomos col-12">
        <div className="letrero">
          <div class=" container col-12">
            <div class="scroll">
              <div class="RightToLeft">
                <p>Sublibon &nbsp;</p>
                <p>Sublibon &nbsp;</p>
                <p>Sublibon &nbsp;</p>
                <p>Sublibon &nbsp;</p>
                <p>Sublibon &nbsp;</p>
                <p>Sublibon &nbsp;</p>
                <p>Sublibon &nbsp;</p>
                <p>Sublibon &nbsp;</p>
                <p>Sublibon &nbsp;</p>
                <p>Sublibon &nbsp;</p>
                <p>Sublibon &nbsp;</p>
                <p>Sublibon &nbsp;</p>
              </div>
              <div class="LeftToRight">
                <p>"Sublimados en muchas cositas" &nbsp;</p>
                <p>"Sublimados en muchas cositas" &nbsp;</p>
                <p>"Sublimados en muchas cositas" &nbsp;</p>
                <p>"Sublimados en muchas cositas" &nbsp;</p>
              </div>
            </div>
          </div>
          <div class="col-9 col-md-7 px-3">
           
            <div class="col-md-8 mt-4">
              <div class="card profile-card-5">
                <div class="card-img-block">
                  <img
                    class="card-img-top"
                    src={perfil}
                    alt="Card image cap"
                  />
                </div>
                <div class="card-body pt-0">
                  <h2 class="card-title"> Marina Soledad Del Bono</h2>
                  <p class="card-text">
                    <strong>¡Descubre la historia de una tienda de sublimados y regalos
                    que ha encantado a sus clientes durante más de dos décadas!</strong>&nbsp;
                    Con más de 20 años de experiencia, nuestro establecimiento
                    ha sido el destino preferido para aquellos que buscan
                    sorprender y deleitar a sus seres queridos con regalos
                    únicos y personalizados. Desde prendas sublimadas hasta
                    artículos de regalería cuidadosamente seleccionados, cada
                    producto cuenta una historia de calidad, creatividad y
                    atención al detalle. Únete a nosotros en este viaje a través
                    del tiempo mientras celebramos nuestro legado y miramos
                    hacia un futuro lleno de nuevas ideas y emocionantes
                    descubrimientos en cada rincón de nuestra tienda."
                  </p>
                </div>
              </div>
              <p class="mt-3 w-100 float-left text-center">
                <strong>SUBLIBON</strong>
              </p>
            </div>
          </div>
        </div>
        <br />
      </section>
      <Marcas></Marcas>
    </>
  );
};
export default QuienesSomos;
