import { CartState } from "../../context/Context";
import { useEffect, useState, useRef } from "react";
import { FaShoppingCart } from "react-icons/fa";
import { AiFillDelete } from "react-icons/ai";
import { Badge, Button, Dropdown, Nav } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import logo from "../../images/raquetas/logo.webp";

const Header = () => {
  const [horiSelectorStyle, setHoriSelectorStyle] = useState({});
  const location = useLocation();
  const tabsNewAnimRef = useRef(null);
  const {
    state: { cart },
    dispatch /* 
    productDispatch, */,
  } = CartState();

  useEffect(() => {
    const tabsNewAnim = tabsNewAnimRef.current;
    const selectorNewAnim = tabsNewAnim.querySelectorAll("li").length;
    const activeItemNewAnim = tabsNewAnim.querySelector(".active");
    const activeWidthNewAnimHeight = activeItemNewAnim.offsetHeight;
    const activeWidthNewAnimWidth = activeItemNewAnim.offsetWidth;
    const itemPosNewAnimTop = activeItemNewAnim.offsetTop;
    const itemPosNewAnimLeft = activeItemNewAnim.offsetLeft;

    setHoriSelectorStyle({
      top: `${itemPosNewAnimTop}px`,
      left: `${itemPosNewAnimLeft}px`,
      height: `${activeWidthNewAnimHeight}px`,
      width: `${activeWidthNewAnimWidth}px`,
    });
  }, [location.pathname]);

  const handleItemClick = (e) => {
    const item = e.currentTarget;
    const activeWidthNewAnimHeight = item.offsetHeight;
    const activeWidthNewAnimWidth = item.offsetWidth;
    const itemPosNewAnimTop = item.offsetTop;
    const itemPosNewAnimLeft = item.offsetLeft;

    setHoriSelectorStyle({
      top: `${itemPosNewAnimTop}px`,
      left: `${itemPosNewAnimLeft}px`,
      height: `${activeWidthNewAnimHeight}px`,
      width: `${activeWidthNewAnimWidth}px`,
    });
  };

  return (
    <>
      <nav className="navbar navbar-expand-custom navbar-mainbg fixed-top">
        <a className="navbar-brand navbar-logo" href="#">
          <img src={logo} alt="" />
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon">X</span>
        </button>
        <div
          className="collapse navbar-collapse"
          id="navbarSupportedContent"
          ref={tabsNewAnimRef}
        >
          <ul className="navbar-nav ml-auto">
            <div className="hori-selector" style={horiSelectorStyle}>
              <div className="left"></div>
              <div className="right"></div>
            </div>
            <li className="nav-item active" onClick={handleItemClick}>
              <a className="nav-link" href="/#productos">
                Productos
              </a>
            </li>
            <li className="nav-item " onClick={handleItemClick}>
              <a className="nav-link" href="/#quienesSomos">
                Quienes somos
              </a>
            </li>
            <li className="nav-item" onClick={handleItemClick}>
              <a className="nav-link" href="/#clientes">
                Clientes
              </a>
            </li>
            <li className="nav-item" onClick={handleItemClick}>
              <a className="nav-link" href="/#contacto">
                Contacto
              </a>
            </li>
          </ul>
        </div>
      </nav>
      <Nav className="cart">
        <Dropdown alignRight>
          <Dropdown.Toggle variant="danger">
            <FaShoppingCart color="white" fontSize="25px" />
            <Badge className="numberCart">{cart.length}</Badge>
          </Dropdown.Toggle>

          <Dropdown.Menu style={{ minWidth: 370 }}>
            {cart.length > 0 ? (
              <>
                {cart.map((prod) => (
                  <span className="cartitem" key={prod.id}>
                    <img
                      src={prod.image}
                      className="cartItemImg"
                      alt={prod.name}
                    />
                    <div className="cartItemDetail">
                      <span>{prod.name}</span>
                      <span>$ {prod.price.split(".")[0]}</span>
                    </div>
                    <AiFillDelete
                      fontSize="20px"
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        dispatch({
                          type: "REMOVE_FROM_CART",
                          payload: prod,
                        })
                      }
                    />
                  </span>
                ))}
                <Link to="/cart">
                  <Button style={{ width: "95%", margin: "0 10px" }}>
                    Ir al Carrito
                  </Button>
                </Link>
              </>
            ) : (
              <span style={{ padding: 10 }}>Carrito vacio!</span>
            )}
          </Dropdown.Menu>
        </Dropdown>
      </Nav>
    </>
  );
};

export default Header;
