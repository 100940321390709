import { Button, Form } from "react-bootstrap";
import { CartState } from "../context/Context"; 
import $ from "jquery";

const Filters = () => {
  const {
    productDispatch,
    productState: { byStock, byFastDelivery, sort, byRating },
  } = CartState();
  $(document).ready(function () {
    $("[data-toggle=offcanvas]").click(function () {
      $(".row-offcanvas").toggleClass("active");
    });
  });

  // make state for rating

  return (
    <>
      <button
        class="btn btn-danger"
        type="button"
        data-bs-toggle="offcanvas"
        data-bs-target="#offcanvasWithBothOptions"
        aria-controls="offcanvasWithBothOptions"
      >
        Ordenar
      </button>

      <div
        class="offcanvas offcanvas-start filters"
        data-bs-scroll="true"
        tabindex="-1"
        id="offcanvasWithBothOptions"
        aria-labelledby="offcanvasWithBothOptionsLabel"
      >
        <div class="offcanvas-header">
          <h2 class="offcanvas-title" id="offcanvasWithBothOptionsLabel">
          Ordenar
          </h2>
          <button
            type="button btn-danger"
            class="btn-close"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div class="offcanvas-body">
          <div >
            <span>
              <Form.Check
                inline
                label="De Menor a Mayor"
                name="group1"
                type="radio"
                id={`inline-1`}
                onChange={() =>
                  productDispatch({
                    type: "SORT_BY_PRICE",
                    payload: "lowToHigh",
                  })
                }
                checked={sort === "lowToHigh" ? true : false}
              />
            </span>
            <br/>
            <span>
              <Form.Check
                inline
                label="De Mayor a Menor"
                name="group1"
                type="radio"
                id={`inline-2`}
                onChange={() =>
                  productDispatch({
                    type: "SORT_BY_PRICE",
                    payload: "highToLow",
                  })
                }
                checked={sort === "highToLow" ? true : false}
              />
            </span>
            <br/>
            <Button
              variant="danger"
              onClick={() =>
                productDispatch({
                  type: "CLEAR_FILTERS",
                })
              }
            >
              Limpiar orden
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Filters;