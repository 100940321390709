import React, { useRef, useEffect } from "react";
/* imports para las cards */
import adidas from "./multimedia/cliente.jpeg";
import nike from "./multimedia/cliente2.jpeg";
import setfanos from "./multimedia/remeraP.png";
import Card from "./Card";

const Marcas = () => {
  /* SCROLL HORIZONTAL */
  const espacioRef = useRef(null);
  const horizontalRef = useRef(null);

  useEffect(() => {
    const spaceHolder = espacioRef.current;
    const horizontal = horizontalRef.current;

    function calcDynamicHeight(ref) {
      const vw = window.innerWidth;
      const vh = window.innerHeight;
      const anchoObj = ref.scrollWidth;
      return anchoObj - vw + vh + 150;
    }

    spaceHolder.style.height = `${calcDynamicHeight(horizontal)}px`;

    const handleScroll = () => {
      const sticky = document.querySelector(".sticky");
      horizontal.style.transform = `translateX(-${sticky.offsetTop}px)`;
    };

    window.addEventListener("scroll", handleScroll);

    const handleResize = () => {
      spaceHolder.style.height = `${calcDynamicHeight(horizontal)}px`;
    };

    window.addEventListener("resize", handleResize);

    const items = document.querySelectorAll(".appear");

    const active = function (entries) {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("inview2");
        } else {
          entry.target.classList.remove("inview2");
        }
      });
    };

    const io2 = new IntersectionObserver(active);
    items.forEach((item) => io2.observe(item));

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleResize);
      io2.disconnect();
    };
  }, []);
  /* ARRAY CARDS*/
  let cardsArray = [
    {
      character: setfanos,
      title: "REMERA",
      marcaImg: adidas,
      marcaImgAlt: "adidas",
      caracterAlt: "stefanos Tsisipas",
    },
    {
      character: nike,
      title: "REMERA",
      marcaImg: nike,
      marcaImgAlt: "Nike logo",
      caracterAlt: "Roger Federer",
    },/* 
    {
      character: novak,
      title: "",
      marcaImg: lacoste,
      marcaImgAlt: "lacoste",
      caracterAlt: "Novak Djokovic",
    },
    {
      character: nadal,
      title: "",
      marcaImg: babolat,
      marcaImgAlt: "babolat",
      caracterAlt: "Rafael Nadal",
    },
    {
      character: murray,
      title: "",
      marcaImg: head,
      marcaImgAlt: "Head ",
      caracterAlt: "Andy Murray",
    }, */
  ];
 
  return (
    <>
      <h1 style={{ background: "#7831bf", color: "#fff","margin":"0","paddingTop":"10vh" }} id="clientes">Clientes</h1>
      <section className="containerCards">
        <div className="space-holder" ref={espacioRef}>
          <div className="sticky">
            <div className="horizontal" ref={horizontalRef}>
              <section role="feed" className="cards">
                {cardsArray.map((item) => (
                  <Card
                    caracter={item.character}
                    title={item.title}
                    marcaImg={item.marcaImg}
                    marcaImgAlt={item.marcaImgAlt}
                    caracterAlt={item.characterAlt}
                  />
                ))}{" "}
              </section>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Marcas;
